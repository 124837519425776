import { memo } from "react";
import DefaultTemplate from "./Template";
import type { TemplateProps } from "./Template";
import type { KcProps } from "keycloakify/lib/components/KcProps";
import type { KcContextBase } from "keycloakify/lib/getKcContext/KcContextBase";
import type { I18n } from "keycloakify/lib/i18n";

import classes from './LoginPageExpired.module.scss';

export type LoginPageExpiredProps = KcProps & {
  kcContext: KcContextBase.LoginPageExpired;
  i18n: I18n;
  doFetchDefaultThemeResources?: boolean;
  Template?: (props: TemplateProps) => JSX.Element | null;
};

const LoginPageExpired = memo((props: LoginPageExpiredProps) => {
  const { kcContext, i18n, doFetchDefaultThemeResources = true, Template = DefaultTemplate, ...kcProps } = props;
  const { url } = kcContext;
  const { msg } = i18n as any;

  return (
    <Template
      {...{ kcContext, i18n, doFetchDefaultThemeResources, ...kcProps }}
      displayMessage={false}
      showCustomInternationalization
      headerNode={msg("pageExpiredTitle")}
      formNode={
        <>
          <div className={classes.topDivider} />
          <p>
            {msg("pageExpiredMsg1")}
              <a className={classes.clickLink} id="loginRestartLink" href={url.loginRestartFlowUrl}>
                <button className={classes.submitButton} type="submit">
                  {msg("proceed")}
                </button>
              </a>
          </p>
        </>
      }
    />
  );
});

export default LoginPageExpired;
