import { useI18n as useI18nBase } from "keycloakify";;

type Props = Omit<Parameters<typeof useI18nBase>[0], "extraMessages">;

export function useI18n(props: Props) {
    const { kcContext } = props;
    const { locale } = kcContext;
    const params = new URLSearchParams(window.location.search);

    return useI18nBase({
        kcContext: {
            ...kcContext,
            locale: locale && {
                ...locale,
                currentLanguageTag: params.get('kc_locale') ?? locale.currentLanguageTag,
            }
        },
        "extraMessages": {
            "en": {
                "alphanumericalCharsOnly": "Only alphanumerical characters",
				"gender": "Gender",
				// Here we overwrite the default english value for the message "doForgotPassword"
                // that is "Forgot Password?" see: https://github.com/InseeFrLab/keycloakify/blob/f0ae5ea908e0aa42391af323b6d5e2fd371af851/src/lib/i18n/generated_messages/18.0.1/login/en.ts#L17
                "doForgotPassword": "I forgot my password",
                "doLogIn": "Sign in with",
                "writeTo": "Contact",
                "customerSupport": "support team",
                "doSubmit": "Submit",
                "doCancel": "Cancel",
                "loginPageTitle": "Sign in",
                "LoginUpdateProfile.helpText": "Some parameters are missing for user login. Fill in the empty fields to continue working in the system.",
                "proceedWithAction": "» Click here to proceed",
                "proceed": "Proceed",
                "pageExpiredTitle": "Page has expired",
                "pageExpiredMsg1": "Go to the authorization page to continue working in the system.",
            },
            "ru": {
                /* spell-checker: disable */
                "alphanumericalCharsOnly": "Только буквенно-цифровые символы",
				"gender": "Пол",
				"doForgotPassword": "я забыл мой пароль",
                /* spell-checker: enable */
                "doLogIn": "Войти через",
                "writeTo": "Написать в",
                "customerSupport": "службу поддержки",
                "doSubmit": "Сохранить",
                "doCancel": "Отмена",
                "loginPageTitle": "Войти",
                "LoginUpdateProfile.helpText": "Для входа пользователя в систему не хватает некоторых параметров. Заполните пустые поля для продолжения работы в системе.",
                "proceedWithAction": "» Кликните здесь для продолжения",
                "proceed": "Перейти",
                "pageExpiredTitle": "Страница устарела",
                "pageExpiredMsg1": "Перейдите на страницу авторизации, чтобы продолжить работу в системе.",
            },
        },
    });
}

export type I18n = NonNullable<ReturnType<typeof useI18n>>;
