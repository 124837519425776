import { createRoot } from "react-dom/client";
import { StrictMode, Suspense } from "react";
import { kcContext } from "./KcApp/kcContext";
import "./index.css";

import KcApp from './KcApp';

if (kcContext !== undefined) {
  console.log(kcContext);
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Suspense>
      {kcContext && <KcApp kcContext={kcContext} />}
    </Suspense>
  </StrictMode>,
);
