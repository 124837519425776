import { lazy, Suspense, useEffect } from "react";
import type { KcContext } from "./kcContext";
import KcAppBase, { defaultKcProps } from "keycloakify";
import { useI18n } from "./i18n";

import "./KcApp.css";
import LoginPageExpired from "./LoginPageExpired";
import { isDevelopment } from "helpers/isDevelopment";
import { DevelopmentConsole } from "components/DevelopmentConsole";

const Login = lazy(() => import("./Login"));
const LogoutConfirm = lazy(() => import("./LogoutConfirm"));
const LoginUpdateProfile = lazy(() => import("./LoginUpdateProfile"));
const Error = lazy(() => import("./Error"));
const Info = lazy(() => import("./Info"));

export type Props = {
  kcContext: KcContext;
};

type supportedLang = { languageTag: string; url: string; label: string };

const supportedLangs: Record<string, supportedLang> = {
  en: {
    label: "En",
    languageTag: "en",
    url: "/auth/realms/myrealm/login-actions/authenticate?cl…ee6c2834-46a4-4a20-a1b6-f6d6f6451b36&kc_locale=en",
  },
  ru: {
    label: "Ru",
    languageTag: "ru",
    url: "/auth/realms/myrealm/login-actions/authenticate?client_id=account&tab_id=HoAx28ja4xg&execution=ee6c2834-46a4-4a20-a1b6-f6d6f6451b36&kc_locale=ru",
  },
};

export default function KcApp({ kcContext: kcContextBase }: Props) {
  kcContextBase.realm = {
    ...kcContextBase.realm,
    password: false,
    displayNameHtml:
      kcContextBase.realm.name === kcContextBase.realm.displayNameHtml
        ? " "
        : kcContextBase.realm.displayNameHtml,
  };

  const kcContext =
    process.env.NODE_ENV === "development"
      ? {
          ...kcContextBase,
          locale: {
            ...(kcContextBase?.locale ?? { currentLanguageTag: "ru" }),
            supported:
              kcContextBase?.locale?.supported
                .filter(({ languageTag }) => ["ru", "en"].includes(languageTag))
                .map(({ languageTag }) => supportedLangs[languageTag]) ?? [],
          },
        }
      : kcContextBase;

  const i18n = useI18n({ kcContext });

  useEffect(() => {
    if (i18n) {
      document.title = i18n.msgStr("loginPageTitle");
    }
  }, [i18n, i18n?.currentLanguageTag]);

  //NOTE: Locales not yet downloaded
  if (i18n === null) {
    return null;
  }

  const props = {
    i18n,
    ...defaultKcProps,
    kcHeaderWrapperClass: ['text-center'],
    styles: [],
    stylesCommon: [],
    // NOTE: The classes are defined in ./KcApp.css
  };

  // copy components from
  // https://github.com/InseeFrLab/keycloakify/tree/v6.8.5/src/lib/components
  // if you need to implement custom design for some specific page.

  return (
    <Suspense>
      {isDevelopment && <DevelopmentConsole />}
      {(() => {
        switch (kcContext.pageId) {
          case "logout-confirm.ftl":
            return <LogoutConfirm {...{ kcContext, ...props }} />;
          case "login-page-expired.ftl":
            return <LoginPageExpired {...{ kcContext, ...props }} />;
          case "login-update-profile.ftl":
              return <LoginUpdateProfile {...{ kcContext, ...props }} />;
          case "login.ftl":
            return <Login {...{ kcContext, ...props }} />;
          case "error.ftl":
            return <Error {...{ kcContext, ...props }} />;
          case "info.ftl":
            return <Info {...{ kcContext, ...props }} />;
          default:
            return <KcAppBase {...{ kcContext, ...props }} />;
        }
      })()}
    </Suspense>
  );
}
