import { getQueryParams } from "helpers/getQueryParams";
import { isDevelopment } from "helpers/isDevelopment";
import { getKcContext } from "keycloakify/lib/getKcContext";

const queryParams = getQueryParams();

export const { kcContext } = getKcContext<{ pageId: "login.ftl" }>({
  // Uncomment to test the login page for development.
  // Try with another page like "register-user-profile.ftl"
  // DON'T forget to re-comment before publishing to production.
  // You must run 'yarn keycloak' at least once before testing locally.
  ...(isDevelopment ? { mockPageId: queryParams?.page ?? "info.ftl" } : {}),
  mockData: [
    {
      pageId: "login.ftl",
      locale: {
        currentLanguageTag: "ru", //When we test the login page we do it in french
      },
      realm: {
        loginWithEmailAllowed: false,
        rememberMe: false,
        internationalizationEnabled: true,
        displayName: " ",
        displayNameHtml: ` `,
        registrationEmailAsUsername: false,
        resetPasswordAllowed: false,
        password: true,
      },
      social: {
        displayInfo: true,
        providers: [
          {
            displayName: "Aggregion",
            providerId: "aggregion",
            loginUrl:
              "/realms/myrealm/broker/aggregion/login?client_id=secu…_code=kWsei6vXfNudg-JUUvwW39eRXBhwDTGrv1pCjmQfks0",
            alias: "aggregion",
          },
          {
            displayName: "GitHub",
            providerId: "github",
            loginUrl:
              "/realms/myrealm/broker/github/login?client_id=secu…_code=kWsei6vXfNudg-JUUvwW39eRXBhwDTGrv1pCjmQfks0",
            alias: "github",
          },
          {
            displayName: "Facebook",
            providerId: "facebook",
            loginUrl:
              "/realms/myrealm/broker/facebook/login?client_id=se…_code=kWsei6vXfNudg-JUUvwW39eRXBhwDTGrv1pCjmQfks0",
            alias: "facebook",
          },
        ],
      },
      registrationDisabled: true,
    },
  ],
});

export type KcContext = NonNullable<typeof kcContext>;
