import { useMemo } from 'react';
import { clsx } from "keycloakify/lib/tools/clsx";

import { availablePages } from 'helpers/availablePages';
import classes from './DevelopmentConsole.module.scss';
import { getQueryParams } from 'helpers/getQueryParams';

export const DevelopmentConsole = () => {
  const currentPage = useMemo(() => getQueryParams().page, []);

  return <div className={classes.container}>
    <div className={classes.availablePages}>
      {availablePages.map(page => (
        <a
          key={page}
          className={clsx(classes.availablePage, { [classes.active]: currentPage === page })}
          href={`/?page=${page}`}>
            {page}
        </a>
      ))}
    </div>
  </div>;
}
